<template>
  <main class="no-main">
    <section class="section--single-post ps-page--business">
      <div class="ps-breadcrumb">
        <div class="container-fluid-sy">
          <ul class="ps-breadcrumb__list">
            <li class="active"><router-link :to="{ name: 'home' }">Home</router-link></li>
            <li class="active"><router-link :to="{ name: 'dashboard' }">MyAccount</router-link></li>
            <li><a href="javascript:void(0);">Account Settings</a></li>
          </ul>
        </div>
      </div>

      <section class="section--checkout">
        <div class="container mb-3">
          <h2 class="page__title">Account Details</h2>
          <div class="checkout__content"><hr>
            <form @submit.prevent="onSubmit()">
              <div class="row">
                <div class="col-12 col-lg-12">
                  <h3 class="checkout__title">Account Details</h3>
                  <div class="row">
                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>First name: <span>*</span></label>
                        <input
                          class="form-control"
                          type="text"
                          required
                          placeholder="Enter the first name"
                          v-model="user.first_name"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>last name: <span>*</span></label>
                        <input
                          class="form-control"
                          type="text"
                          required
                          v-model="user.last_name"
                          placeholder="Enter the last name"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>Gender: <span>*</span></label>
                        <select v-model="user.gender" class="form-control drobdown" name="state">
                          <option>{{ user.gender }}</option>
                          <option v-if="(user.gender = 'male')">Female</option>
                          <option v-else>Male</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>Date of birth: <span>*</span></label>
                        <!-- <input
                          class="form-control"
                          type="date"
                          required
                          v-model="user.date_of_birth"
                          placeholder="Enter the last name"
                        /> -->            
                        <Datepicker style="background-color: white;" class="form-control" v-model="user.date_of_birth" placeholder="yyyyy-mm-dd"></Datepicker>
                      </div>
                    </div>

                    <div class="col-12 col-lg-4 form-group--block">
                      <label>Phone: <span>*</span></label>
                      <div class="input-group"
                          :class="{ 'is-invalid': $errors.has('phonecode') || $errors.has('phone') }"
                      >
                        <select class="form-control"
                          :class="{ 'is-invalid': $errors.has('phonecode') }"
                          v-model="user.phonecode" >
                          <option value="" disabled>Select</option>
                          <option v-for="(country, index) in countriesWithPhonecode" :key="index" :value="country.phonecode">
                            ({{ country.phonecode }}) {{ country.name }}
                          </option>
                        </select>

                        <input
                          class="form-control"
                          :class="{ 'is-invalid': $errors.has('phone') }"
                          type="text"
                          placeholder="Enter the phone number"
                          v-model="user.phone"
                        />
                      </div>

                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('phonecode')"
                      >
                        {{ $errors.first("phonecode") }}
                      </div>
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('phone')"
                      >
                        {{ $errors.first("phone") }}
                      </div>
                    </div>

                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>Email Address: <span>*</span></label>
                        <input
                          class="form-control"
                          type="email"
                          required
                          v-model="user.email"
                          placeholder="Enter the email address"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="input-group-append mt-4">
                    <button class="btn btn-lg save-btn">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="container">
          <div class="checkout__content">
            <div class="row">
              <div class="col-12 col-lg-12">
                <h3 class="checkout__title">Change Password</h3>

                <form @submit.prevent="onChangePassword()">
                  <div class="row">
                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>Current Password: <span>*</span></label>
                        <input
                          type="password"
                          placeholder="Enter the current password"
                          class="form-control"
                          :class="{ 'is-invalid': $errors.has('oldpassword') }"
                          v-model="pwdForm.oldpassword"
                        />
                        <div class="invalid-feedback" v-show="$errors.has('oldpassword')">
                          {{ $errors.first("oldpassword") }}
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>New Password: <span>*</span></label>
                        <input
                          type="password"
                          required
                          placeholder="Enter the new password"
                          class="form-control"
                          :class="{ 'is-invalid': $errors.has('password') }"
                          v-model="pwdForm.password"
                        />
                        <div class="invalid-feedback" v-show="$errors.has('password')">
                          {{ $errors.first("password") }}
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-lg-4 p-0">
                      <div class="col-12 col-lg-12 form-group--block">
                        <label>Confirm Password: <span>*</span></label>
                        <input
                          type="password"
                          required
                          placeholder="Enter the confirm password"
                          class="form-control"
                          :class="{ 'is-invalid': $errors.has('password_confirmation') }"
                          v-model="pwdForm.password_confirmation"
                        />
                        <div class="invalid-feedback" v-show="$errors.has('password_confirmation')">
                          {{ $errors.first("password_confirmation") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="input-group-append mt-4">
                    <button class="btn btn-lg save-btn">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Datepicker from 'vue3-datepicker';

export default {
  data() {
    return {
      pwdForm: {
        oldpassword: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  components:{
    Datepicker,
  },

  mounted() {
    this.fetchCountriesWithPhonecode();
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("country", ["countriesWithPhonecode"]),
  },

  methods: {
    ...mapActions("auth", ["updateUser", "changePassword"]),
    ...mapActions("country", ["fetchCountriesWithPhonecode"]),

    onSubmit() {
      this.updateUser(this.user).then(() => {
        this.$router.push({ name: "dashboard" });
      });
    },

    onChangePassword() {
      this.changePassword(this.pwdForm).then(() => {
        this.$router.push({ name: "dashboard" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.save-btn {
  width: 70px;
  height: 35px;
  background-color: #ff7200;
  color: #ffff;
}
</style>
